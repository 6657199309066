import { AppBar, Button, CircularProgress, IconButton, Input, List, ListItem, ListItemIcon, ListItemText, Slider, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import "@fontsource/rubik";
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import CustomCard from "../../../components/CustomCard";
import luciLogo from "../../../../../assets/copilot_logo_dark.png";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AuthLayout from "../../../../layouts/AuthLayout";
import CustomUserCard from "../../CustomUserCard";
import ProjectList from "../../../../dashboard/listview/ProjectList";
import DrawerProjectList from "../../../../dashboard/listview/DrawerProjectList";
import PopupVideoPlayer from "../../../../../components/PopupVideoPlayer";
import lucihub from "../../../../../assets/butterfly_navigation.png";
import CustomTooltip from "../../../../../components/CustomTooltip";
import DeleteConfirmationPopup from "../../../../dashboard/components/DeleteConfirmationPopup";
import { updateCommonState } from "../../../../../redux/commonSlice";
import useVoiceProfileActions from "../hooks/useVoiceProfileActions";
import { updateVoiceState } from "../../../../../redux/voiceSlice";
import CustomInput from "../../../../eventcreation/components/CustomInput";
import CustomAutocomplete from "../../../../eventcreation/components/CustomAutoComplete";
import { azureLanguageNames } from "../../../../../constants/constants";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import useTtsSample from "../../voiceStudio/hooks/useTtsSample";
import { afterValidate, escapeForSSML, getFirstWord, reduceWords, removeHtmlTags, sortArrayByCategory } from "../../../../../utils/commonServices";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import CustomGroupedAutoComplete from "../../../../eventcreation/components/CustomGroupedAutoComplete";
import { updateContentState } from "../../../../../redux/contentSlice";
import { getErrorMsg } from "../../../../../utils/validator";
import CustomButton from "../../../../../components/CustomButton";
import CustomButtonlist from "../../../../../components/CustomButtonList";


const VoiceProfileManager = (props) => {


    const pendingCallReducer = useSelector((state) => state.pendingCallReducer);
    const voiceReducer = useSelector((state) => state.voiceReducer);
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const userReducer = useSelector((state) => state.persistedReducers.userReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const { sampleRef, handleGenerateVoiceSample, pauseSample, playSample } = useTtsSample()
    const { createVoiceProfile, updateVoiceProfile, deleteVoiceProfile } = useVoiceProfileActions();
    const { getVoiceProfiles } = useVoiceProfileActions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isProfileSelected, setIsProfileSelected] = useState(false)
    const [buttonState, setButtonState] = useState('Create Profile')
    const [selectedProfileState, setSelectedProfileState] = useState({
        name: '',
        description: '',
    })
    const [profileNameValidationFlag, setProfileNameValidationFlag] = useState(false)
    const [profileDescriptionValidationFlag, setProfileDescriptionValidationFlag] = useState(false)
    const [profileVoiceValidationFlag, setProfileVoiceValidationFlag] = useState(false)
    const [toneValidationFlag, setToneValidationFlag] = useState(false)
    const [accentValidationFlag, setAccentValidationFlag] = useState(false)

    const options = sortArrayByCategory(dropdownReducer?.voices?.length > 0 && dropdownReducer?.voices?.map((item) => {
        return { ...item, category: item.SecondaryLocaleList?.length > 0 ? "Global Accents" : item.StyleList?.length > 0 ? "Versatile Tones" : "Standard Voices" }
    }), { 'Global Accents': 1, "Versatile Tones": 2, "Standard Voices": 3 })


    const categoryDescription = {
        'Global Accents': "Choose voices with various accents to add cultural distinction to your content.",
        "Versatile Tones": "Select voice styles to match the desired tone and mood of your content.",
        "Standard Voices": "Clear and straightforward voices without accents or style variations."
    }

    useEffect(() => {
        getVoiceProfiles();
    }, [])
    useEffect(() => {
        if(voiceReducer?.voice_profiles?.length > 0) {
           
            const selectedProfile =  voiceReducer?.voice_profiles?.find(item => (item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile))

            if(!!selectedProfile) {
                profileSelectionHandler(selectedProfile)
            } else {
                profileSelectionHandler(voiceReducer?.voice_profiles[0])  
            }


            
        }
       
    }, [voiceReducer?.voice_profiles])
    

    const getVoiceProfilesCallback =()=>{
        const selectedProfile = voiceReducer?.voice_profiles?.find(profile => profile?.id == voiceReducer?.selected_voice_profile)
        if(!!selectedProfile) {
            profileSelectionHandler(selectedProfile)
        } else {
            profileSelectionHandler(voiceReducer?.voice_profiles[0])
        }
        
    }

    useEffect(() => {
        if (userReducer.is_logged) {
            if (userReducer.butterfly_access && !userReducer.page_loading) {
                //   navigate(!!window.localStorage.getItem('destination_path') ? window.localStorage.getItem('destination_path') : '/dashboard', {replace: true})
                // navigate('/dashboard', {replace: true})
            } else {
                navigate('/no-access', { replace: true })
            }
        }

    }, [userReducer.butterfly_access, userReducer.is_logged])

    const playSampleHandler = () => {
        if (voiceReducer?.sample_url == '') {
            handleGenerateVoiceSample()
        }
        if (voiceReducer?.is_sample_playing) {
            pauseSample()
        } else if (voiceReducer?.sample_url != '') {
            playSample()
        }

        dispatch(updateVoiceState({ is_sample_playing: !voiceReducer?.is_sample_playing }))

    }

    const commonChangeHandler = () => {

        if (!!sampleRef.current) {
            pauseSample();
            sampleRef.current.src = "";
        }
        dispatch(updateVoiceState({
            sample_url: "",
            audio_url: "",
            // is_speech_playing: false,
            is_sample_playing: false,
        }))
    }



    const handleClose = () => {
        commonChangeHandler();
        dispatch(updateVoiceState({ is_profile_edit: false, voice_profile_creation_popup: false }))
        navigate(-1)
    };

    const profileSelectionHandler = (profile) => {
        //!!voiceReducer?.is_sample_playing && pauseSample();
        setIsProfileSelected(true)
       // setButtonState('Duplicate Profile')
        commonChangeHandler()
        console.log("selection", profile)
        dispatch(updateVoiceState({
            selected_style: profile?.voice_style,
            selected_accent: profile?.voice_accent,
            selected_voice: dropdownReducer?.voices?.find(item => item.ShortName == profile?.voice),
            profile_description: profile?.description,
            profile_name: profile?.name,
            pitch: profile?.voice_pitch,
            rate: profile?.voice_speed,
            sample_text: (!!profile?.script && profile?.is_default_profile == 1) ? profile?.script :  escapeForSSML(removeHtmlTags(reduceWords(contentReducer?.editor_text, 30))),
            selected_voice_profile: profile?.id,
            is_selected_default: !!profile?.is_default_profile,
            selected_profile_action_id: 2
        }))
      //  duplicateProfileHandler()
        setSelectedProfileState({
            name: profile?.name,
            description: profile?.description,
        })
    }

    // const createNewProfileHandler = () => {
    //     setIsProfileSelected(false)
    //     setButtonState('Create Profile')
    //     commonChangeHandler()
    //     dispatch(updateVoiceState({
    //         selected_profile_id: '',
    //         selected_style: '',
    //         selected_accent: '',
    //         selected_voice: '',
    //         profile_description: '',
    //         profile_name: '',
    //         pitch: 0,
    //         rate: 0,
    //         sample_text: '',
    //         selected_voice_profile: 0,
    //         is_selected_default: false,
    //         selected_profile_action_id: 1
    //     }))
    // }

    // const updateProfileHandler = () => {
    //     setIsProfileSelected(true)
    //     setButtonState('Update Profile')
    //     commonChangeHandler()
    //     dispatch(updateVoiceState({
    //         selected_profile_action_id: 3,
    //         is_profile_edit: true,
    //         profile_description: selectedProfileState?.description,
    //         profile_name: selectedProfileState?.name,
    //     }))
    // }

    // const duplicateProfileHandler = () => {
    //     setIsProfileSelected(true)
    //     setButtonState('Duplicate Profile')
    //     commonChangeHandler()
    //     dispatch(updateVoiceState({
    //         profile_description: '',
    //         profile_name: '',
    //         selected_profile_action_id: 2,
    //         is_profile_edit: false
    //     }))
    // }

    const deleteProfileHandler = () => {
        console.log('delete')
        deleteVoiceProfile({ id: voiceReducer?.selected_voice_profile }, () => {
            dispatch(updateContentState({ open_delete_popup: false }))
            profileSelectionHandler(voiceReducer?.voice_profiles[0])
        })//api call
        //  createNewProfileHandler()
    }

    const validateInputs = (flag) => {
        setProfileNameValidationFlag(flag)
        setProfileDescriptionValidationFlag(flag)
        setProfileVoiceValidationFlag(flag)

    }

    const setPayload = ()=> {
        const payload = {
            name: voiceReducer?.profile_name,
            description: voiceReducer?.profile_description,
            voice: voiceReducer?.selected_voice?.ShortName,
            voiceStyle: !!voiceReducer?.selected_style ? voiceReducer?.selected_style : "",
            voiceAccent: !!voiceReducer?.selected_accent ? voiceReducer?.selected_accent : "",
            voicePitch: voiceReducer?.pitch,
            voiceSpeed: voiceReducer?.rate
        }
       return payload 
    }

    const saveProfileCallback = () => {
        dispatch(updateVoiceState({ is_profile_edit: false }))
       // handleClose();
    }
    const saveAsSuccessCallback = () => {
        dispatch(updateVoiceState({ voice_profile_view: true, selected_voice_profile: voiceReducer?.voice_profiles[voiceReducer?.voice_profiles?.length - 1]?.id }))
       // handleClose();
    }

   const saveHandler = ()=> {
    const onSubmit = ()=> {
       const payload = setPayload();
        updateVoiceProfile({ ...payload, id: voiceReducer?.selected_voice_profile }, saveProfileCallback)
    }
    validateInputs(true)
    afterValidate(onSubmit);
    
   }

   const saveAsHandler = ()=> {
    const onSubmit = ()=> {
        const payload = setPayload();
        createVoiceProfile(payload, saveAsSuccessCallback)
    }
    validateInputs(true)
    afterValidate(onSubmit);
    
   }

    // const finishedHandler = () => {
    //     validateInputs(true)
    //     afterValidate(onSubmit);
    // }

    const nonMobileView = useMediaQuery('(min-width:600px)');
    const mobileView = useMediaQuery('(max-width:599px)');

    console.log(voiceReducer?.voice_profiles, 'profile page')

    // const voiceProfileManagementOptions = [
    //     { id: 1, label: 'Create new', icon: <AddBoxIcon sx={{ fontSize: { xs: '18px !important', md: '20px !important', lg: '24px !important', xl: '30px !important' } }} />, onClick: () => createNewProfileHandler(), isDisabled: false },

    //     { id: 2, label: 'Duplicate', icon: <ContentCopyIcon sx={{ fontSize: { xs: '18px !important', md: '20px !important', lg: '24px !important', xl: '30px !important' } }} />, onClick: () => duplicateProfileHandler(), isDisabled: false },

    //     { id: 3, label: 'Edit', icon: <EditNoteIcon sx={{ fontSize: { xs: '18px !important', md: '20px !important', lg: '24px !important', xl: '30px !important' } }} />, onClick: () => updateProfileHandler(), isDisabled: voiceReducer?.is_selected_default },
    //     { id: 4, label: 'Delete', icon: <DeleteSweepIcon sx={{ fontSize: { xs: '18px !important', md: '20px !important', lg: '24px !important', xl: '30px !important' } }} />, onClick: () => dispatch(updateContentState({ open_delete_popup: true })), isDisabled: voiceReducer?.is_selected_default },
    // ]


    return <AuthLayout sx={{ padding: mobileView ? '5% 3.2% 2.1% 3.2%' : '2% 3.2% 2.1% 3.2%' }}>
        <Box gap={3} className='flex row a-center j-between' sx={{ pr: { xs: 1, sm: 3 } }} >
            <Box className='flex a-center' gap={2}>
                <Box sx={{ width: { xs: '70px', md: '95px', lg: '120px', xl: '150px' }, mb: 2 }}>
                    <img src={luciLogo} width='100%' />
                </Box>
                {mobileView && <CustomTooltip title='Project List' ><MenuOutlinedIcon sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => dispatch(updateCommonState({ open_drawer_project_list: true }))} /></CustomTooltip>}
            </Box>
            <Box gap={3} className='flex row a-center j-end' sx={{ pr: { xs: 1, sm: 3 } }} >
                <img onClick={() => window.location.href = `${process.env.REACT_APP_LUCIHUB_URL}`} src={lucihub} style={{ borderRadius: '5px', width: '280px', cursor: 'pointer' }} />
                <CustomUserCard />
            </Box>
        </Box>




        {/* <Box id="right" width='100%' minWidth='300px' ml={nonMobileView && 4.5}> */}
        <Box className='flex row j-between a-center' sx={{ height: { xs: '100%', md: '76vh', lg: '74vh', xl: '75vh' }, width: '100%' }} >
            <Box sx={{ height: '100%', width: '35%', display: { xs: 'none', md: 'flex' }, flexDirection: 'column' }}>
                <CustomCard style={{ padding: 24, paddingTop: 16 }} >

                    <Typography sx={{ fontSize: '16px', my: 1, color: '#ffffff', fontWeight: 600 }}>Voice Profiles:</Typography>
                    <Box
                        component='div'
                        sx={{
                            height: '65vh',
                            width: '100%',
                            overflowY: 'scroll',
                            borderRadius: '5px',
                            flexDirection: 'column',
                            backgroundColor: '#ffffff00',
                            // boxShadow: '0px 5px 15px #00000029' 
                        }} >
                        {
                            voiceReducer?.voice_profiles?.length > 0 && voiceReducer?.voice_profiles?.map((profile) => {
                                return <Box
                                    {...props}
                                    className='flex col j-start'
                                    // mb={1.5} 
                                    // ml={1} 
                                    onClick={() => profileSelectionHandler(profile)}
                                    sx={{
                                        borderBottom: '1px solid #cccccc',
                                        py: 0.5,
                                        cursor: 'pointer',
                                        backgroundColor: voiceReducer.selected_voice_profile == profile.id ? '#00D744 !important' : '#ffffff00',
                                        color: voiceReducer.selected_voice_profile == profile.id ? '#000000 !important' : '#ffffff',
                                        "& :hover": {
                                            // color: theme.palette.primary.main,
                                            backgroundColor: '#00D744 !important',
                                            color: '#000000 !important',
                                        },
                                    }}
                                >
                                    <Box className= 'flex row j-between a-center' >
                                        <Box style={{ paddingBottom: '12px', paddingLeft: 8 }} >
                                            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>{profile.name}</Typography>
                                            <Typography sx={{ fontSize: 12, fontWeight: 300 }}>{profile.description}</Typography>
                                        </Box>
                                            
                                        <Box minWidth='24px' >
                                           { 
                                              !profile.is_default_profile  && 
                                                    <DeleteForeverRoundedIcon 
                                                        sx={{cursor:'pointer', mx:1}}
                                                        onClick={() => dispatch(updateContentState({ open_delete_popup: true }))} 
                                                    />      
                                               
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            })
                        }
                    </Box>


                </CustomCard>
            </Box>
            <Box sx={{ height: '100%', width: { xs: '100%', md: '62%' }, display: 'flex', flexDirection: 'column' }}>
                <CustomCard style={{ padding: 24, paddingTop: 16 }} >

                    <Box className="flex col j-between a-center" gap={3} sx={{ width: '100%' }} >
                        <Box sx={{ width: '100%' }}>
                            <Box gap={1} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'black' }}>
                                <IconButton onClick={handleClose} style={{ padding: 0 }} >
                                    <KeyboardBackspaceOutlinedIcon sx={{ color: '#fff', }} />
                                </IconButton>
                                <Typography sx={{ fontFamily: 'Rubik', fontSize: { xs: '10px', sm: '11px', md: '12px', lg: '14px', xl: '20px' }, color: '#fff' }}>Back</Typography>
                            </Box>
                            {/* <Box gap={1} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'black' }}>
                               {<Button
                                    variant="outlined"
                                    style={{
                                        borderRadius: '5px',
                                        border: "none",
                                        textTransform: 'none',
                                        backgroundColor: '#00CEFF',
                                    }}
                                    sx={{
                                        fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '12px', xl: '16px' },
                                        fontWeight: 800,
                                        height: '32px',
                                        fontFamily: 'Rubik',
                                        minWidth: '142px',
                                        display: { xs: 'none', md: 'flex' },
                                        visibility:!isProfileSelected && 'hidden'

                                    }}
                                    disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                    // color="secondary"
                                    onClick={createNewProfileHandler}
                                    // endIcon={<AddRoundedIcon style={{ fontSize: 20 }} />}
                                    startIcon={<AddRoundedIcon style={{ fontSize: 20 }} />}
                                >{'Create New Profile'}</Button>}
                        </Box> */}
                        </Box>
                        <Box className="flex j-between" sx={{ width: '100%', position: { md: 'relative' }, flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'start' }, mt: { md: 4 } }} >
                            
                            {/* <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' }, width: { xs: '100%', md: '80px' }, height: { md: '400px' }, borderRadius: '0px 3px 3px 0px', position: { md: 'absolute' }, left: '-24px', justifyContent: { xs: 'center', md: 'start' }, marginBottom: { xs: 2, md: 0 } }}>

                                <List sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'column' }, borderRight: { md: '1px solid white' } }}>
                                    {voiceProfileManagementOptions?.map((option, index) => (
                                        <ListItem disabled={(!isProfileSelected && option.id != 1) || option.isDisabled} onClick={option.onClick} button key={option.id} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 1, borderBottom: { xs: '1px solid white', md: 'none' }, backgroundColor: voiceReducer?.selected_profile_action_id == option.id && '#08231B' }}>
                                            <ListItemIcon sx={{ color: voiceReducer?.selected_profile_action_id == option.id ? '#00D244' : '#ffffff', display: 'flex', justifyContent: 'center', }}>
                                                {option.icon}
                                            </ListItemIcon>
                                            <Typography textAlign={'center'} sx={{ fontSize: { xs: '8px !important', md: '8px !important', lg: '9px !important', xl: '11px !important' }, color: voiceReducer?.selected_profile_action_id == option.id ? '#00D244' : '#ffffff' }}>{option.label}</Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box> */}

                            <Box className="flex col j-center" sx={{ width: { xs: '90%', sm: '85%', md: '80%', xl: '70%' }, position: { md: 'absolute' }, left: { md: '90px', lg: '130px', xl: '150px' } }}>
                            <Typography sx={{color: '#ffffff', fontSize: '18px', fontWeight: 400, textAlign: 'left', mb:3, width: '100%'}} >Voice profile settings</Typography>
                                <Box sx={{ display: { xs: 'flex', md: 'none' },  mb:4, width: '100%', }} className='col j-center' >
                               
                                    {
                                        voiceReducer?.voice_profiles?.length > 0 && <Box className='flex row j-between'  > 
                                        <Box width='100%' >
                                        <CustomAutocomplete
                                            id="voices"
                                            label='Voice Profile'
                                            labelFlag = {true}
                                            options={voiceReducer?.voice_profiles}
                                            // sx={{ minWidth: { xs: '350px', md: '460px', lg: '650px', xl: '1050px' }, }}
                                           // medium
                                            fullWidth
                                            // onFocus={() => {
                                            //   setScriptLengthValidationFlag(false)
                                            // }}
                                            // onBlur={() => setExportState({enable: false, formatId: ''})}
                                            // required
                                            // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                                            // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                                            // boldError
                                            value={voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile)}
                                            disabled={voiceReducer?.is_generating_speech}
                                            onChange={(event, selection) => {
                                                //!!voiceReducer?.is_sample_playing && pauseSample();
                                                setIsProfileSelected(true)
                                                setButtonState('Update Profile')
                                                commonChangeHandler()
                                                console.log("selection", (!!selection?.script))
                                                dispatch(updateVoiceState({
                                                    selected_style: selection?.style,
                                                    selected_accent: selection?.voice_accent,
                                                    selected_voice: dropdownReducer?.voices?.find(item => item.ShortName == selection?.voice),
                                                    pitch: selection?.voice_pitch,
                                                    rate: selection?.voice_speed,
                                                    sample_text: (!!selection?.script && selection?.is_default_profile == 1) ? selection?.script : escapeForSSML(removeHtmlTags(reduceWords(contentReducer?.editor_text, 30))),
                                                    selected_voice_profile: selection?.id,
                                                    is_selected_default: !!selection?.is_default_profile,
                                                    selected_profile_action_id: 3
                                                }))
                                            }}
                                            placeholder='Select Voice'
                                            getOptionLabel={(option) =>
                                                `${option.name}: ${option.description}`
                                            }
                                            renderOption={(props, option) => {
                                                return <Box {...props} className='flex col j-start' mb={1.5} ml={1} sx={{ borderBottom: '1px solid #cccccc', pb: 0.5 }} >
                                                    <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{option.name}</Typography>
                                                    <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>{option.description}</Typography>

                                                </Box>
                                            }}
                                            msgColor='red'
                                        />
                                        </Box>
                                         <Box sx={{paddingTop: '18px'}} >
                                           { 
                                              !voiceReducer?.is_selected_default  && 
                                                    <DeleteForeverRoundedIcon 
                                                        sx={{cursor:'pointer', mx:1, fontSize: '28px', color: '#ffffff'}}
                                                        onClick={() => dispatch(updateContentState({ open_delete_popup: true }))} 
                                                    />      
                                               
                                            }
                                        </Box>
                                    </Box>

                                    }






                                </Box>
                                <Box className='flex col j-center a-center' sx={{ width: '100%' }} gap={2}>
                                    <Box className='flex col j-center' sx={{ flex: 1, width: '100%', }}   >
                                        <CustomInput
                                            id='name'
                                            label='Profile Name'
                                            onFocus={() => setProfileNameValidationFlag(false)}
                                            onBlur={() => setProfileNameValidationFlag(true)}
                                            // type='sendInput'
                                            required
                                            errorFlag={profileNameValidationFlag && getErrorMsg("voice_profile_name", voiceReducer?.profile_name) != ""}
                                            errorMsg={getErrorMsg("voice_profile_name", voiceReducer?.profile_name)}
                                            fullWidth
                                            // medium
                                            labelFlag={true}
                                           // multiline
                                            maxRows={1}
                                          //  max={300}
                                            bottom={-20}
                                            msgColor='red'
                                            placeholder="Enter a name for this profile"
                                            onChange={(e) => dispatch(updateVoiceState({ profile_name: e.target.value }))}
                                            value={voiceReducer?.profile_name}
                                        //  onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                                        />
                                    </Box>
                                    <Box className='flex col j-start' sx={{ flex: 1, width: '100%', mb: 2 }}  >
                                        <CustomInput
                                            id='description'
                                            label='Profile Descriptiom'
                                            labelFlag={true}
                                            onFocus={() => {
                                                setProfileNameValidationFlag(true)
                                                setProfileDescriptionValidationFlag(false)
                                            }}
                                            onBlur={() => setProfileDescriptionValidationFlag(true)}
                                            // type='sendInput'
                                            required
                                            errorFlag={profileDescriptionValidationFlag && getErrorMsg("voice_profile_description", voiceReducer?.profile_description) != ""}
                                            errorMsg={getErrorMsg("voice_profile_description", voiceReducer?.profile_description)}
                                            fullWidth
                                            // medium
                                           // multiline
                                           // maxRows={4}
                                           // max={500}
                                            bottom={-20}
                                            msgColor='red'
                                            placeholder="Enter a description for this profile"
                                            onChange={(e) => dispatch(updateVoiceState({ profile_description: e.target.value }))}
                                            value={voiceReducer?.profile_description}
                                        // onButtonClick={pageIndex !== totalScreens ? finishedHandler : null}
                                        />
                                    </Box>

                                </Box>
                                <Box className='flex j-center ' gap={3} sx={{ flexDirection: {xs: 'column', lg: 'row'}, alignItems: {xs: 'stretch', lg: 'center'},  width: '100%' }} >
                                    <Box className="flex col j-center a-center" gap={3} sx={{ flex: 1, width: '100%'  }}  >

                                        {
                                            dropdownReducer?.voices?.length > 0 && <Box my={1} sx={{ flex: 1, width: '100%', mb: 2 }}  >
                                            <CustomGroupedAutoComplete
                                                id="voices"
                                                // options={dropdownReducer?.voices}
                                                options={options}
                                                fullWidth

                                               // sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                                                headerTooltip={categoryDescription}
                                                onFocus={() => {
                                                    setProfileNameValidationFlag(true)
                                                    setProfileDescriptionValidationFlag(true)
                                                    setProfileVoiceValidationFlag(false)
                                                }}
                                                onBlur={() => setProfileVoiceValidationFlag(true)}
                                                required
                                                errorFlag={profileVoiceValidationFlag && getErrorMsg("voice", !!options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ? options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : options[5]) != ""}
                                                errorMsg={getErrorMsg("voice", !!options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ? options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : options[5])}
                                                // boldError
                                                labelFlag={true}
                                                value={!!options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ? options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : options[5]}
                                                disabled={voiceReducer?.is_generating_speech}
                                                onChange={(event, selection) => {

                                                    commonChangeHandler()
                                                    dispatch(updateVoiceState({
                                                        selected_style: null,
                                                        selected_accent: null,
                                                        selected_voice: selection
                                                    }))
                                                    !!selection?.StyleList ? dispatch(updateVoiceState({ styles: ["default", ...selection?.StyleList] })) : dispatch(updateVoiceState({ styles: [] }))
                                                    !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

                                                }}
                                                label="Voices"
                                                placeholder='Select Voice'
                                                groupBy={(option) => option.category}
                                                getOptionLabel={(option) =>
                                                    `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
                                                }
                                                renderOption={(props, option) => {
                                                    return <Box {...props} className='flex col j-start' mb={1.5} ml={1} sx={{ borderBottom: '1px solid #cccccc', pb: 0.5 }} >
                                                        <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{`${getFirstWord(option.DisplayName)} | ${option.Gender}`}</Typography>
                                                        {
                                                            option.SecondaryLocaleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                                                {`Available in `} <Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.SecondaryLocaleList?.length}</Typography>{option.SecondaryLocaleList?.length > 1 ? ` different accents` : `accent`}
                                                            </Typography> : option.StyleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                                                {`Available in `}<Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.StyleList?.length}</Typography>{option.StyleList?.length > 1 ? ` different styles` : ` style`}
                                                            </Typography> : <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>{option.LocaleName}</Typography>
                                                        }


                                                    </Box>
                                                }}
                                                msgColor='red'
                                            />
                                        </Box>

                                        }
                                        {/* {
        dropdownReducer?.voices?.length > 0 && <Box sx={{ flex: 1, width: '100%', mb: 2 }}  >
            <CustomAutocomplete
                id="voices"
                options={dropdownReducer?.voices}
                // medium
                // sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                // onFocus={() => {
                //   setScriptLengthValidationFlag(false)
                // }}
                // onBlur={() => setExportState({enable: false, formatId: ''})}
                // required
                // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                // boldError
                value={!!dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ? dropdownReducer?.voices?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : dropdownReducer?.voices[5]}
                disabled={voiceReducer?.is_generating_speech}
                onChange={(event, selection) => {
                    //!!voiceReducer?.is_sample_playing && pauseSample();
                    console.log("selection", selection)
                    commonChangeHandler()
                    dispatch(updateVoiceState({
                        selected_style: null,
                        selected_accent: null,
                        selected_voice: selection
                    }))
                    !!selection?.StyleList ? dispatch(updateVoiceState({ styles: ["default", ...selection?.StyleList] })) : dispatch(updateVoiceState({ styles: [] }))
                    !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

                }}
                label="Voice"
                placeholder='Select Voice'
                getOptionLabel={(option) =>
                    `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
                }
                msgColor='red'
            />


        </Box>
    } */}

                                        {
                                            voiceReducer?.styles?.length > 0 && <Box my={1} sx={{ flex: 1, width: '100%', mb: 2 }}  >
                                                <CustomAutocomplete
                                                    id="style_list"
                                                    //  sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                                                    // onFocus={() =>{
                                                    //     setProfileNameValidationFlag(true)
                                                    //     setProfileDescriptionValidationFlag(true)
                                                    //     setProfileVoiceValidationFlag(true)
                                                    //      setToneValidationFlag(false)}}
                                                    // onBlur={() => setToneValidationFlag(true)}
                                                    // type='sendInput'
                                                    // required
                                                    // errorFlag={toneValidationFlag && getErrorMsg("voice_tone", voiceReducer?.selected_style) != ""}
                                                    // errorMsg={getErrorMsg("voice_tone",voiceReducer?.selected_style)}
                                                    disabled={voiceReducer?.styles?.length == 0}
                                                    options={voiceReducer?.styles}
                                                    value={voiceReducer?.selected_style}
                                                    onChange={(event, selection) => {
                                                        commonChangeHandler()
                                                        dispatch(updateVoiceState({
                                                            selected_accent: null,
                                                            selected_style: selection
                                                        }))
                                                    }}
                                                    labelFlag={true}
                                                    // medium
                                                    label="Voice Tone"
                                                    placeholder='Select Tone'
                                                    getOptionLabel={(option) =>
                                                        `${option}`
                                                    }
                                                    msgColor='red'
                                                />
                                            </Box>
                                        }
                                        {
                                            voiceReducer?.accents?.length > 0 && <Box my={1} sx={{ flex: 1, width: '100%' }}  >
                                                <CustomAutocomplete
                                                    id="accent_list"
                                                    // sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                                                    // onFocus={() =>{
                                                    //     setProfileNameValidationFlag(true)
                                                    //     setProfileDescriptionValidationFlag(true)
                                                    //     setProfileVoiceValidationFlag(true)
                                                    //      setToneValidationFlag(true)
                                                    //      setAccentValidationFlag(false)}}
                                                    // onBlur={() => setAccentValidationFlag(true)}
                                                    // type='sendInput'
                                                    // required
                                                    // errorFlag={accentValidationFlag && getErrorMsg("voice_accent", voiceReducer?.selected_accent) != ""}
                                                    // errorMsg={getErrorMsg("voice_accent",voiceReducer?.selected_accent)}
                                                    disabled={voiceReducer?.accents?.length == 0}
                                                    options={voiceReducer?.accents}
                                                    value={voiceReducer?.selected_accent}
                                                    onChange={(event, selection) => {
                                                        commonChangeHandler();
                                                        dispatch(updateVoiceState({
                                                            selected_accent: selection,
                                                        }))
                                                    }}
                                                    labelFlag={true}
                                                    label="Accent"
                                                    // medium
                                                    placeholder='Select Accent'
                                                    getOptionLabel={(option) =>
                                                        `${!!azureLanguageNames[option] ? azureLanguageNames[option] : ""}`
                                                    }
                                                    msgColor='red'
                                                />
                                            </Box>
                                        }
                                        <Box className='flex row j-start a-center' sx={{ flex: 1, width: '100%', mt: 1 }}  >
                                            {/* <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} >
    <IconButton
        style={{ padding: 1 }}
        disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
        onClick={playSampleHandler}
    >
        <VolumeUpIcon style={{ color: voiceReducer?.is_sample_playing ? '#00ceff' : '#ffffff', fontSize: '28px' }} />
    </IconButton>
</CustomTooltip> */}
                                        </Box>
                                    </Box>
                                    <Box className='flex col j-between' sx={{ gap: 2, flex: 2, }} >
                                        <Box width='100%' gap={3} className='flex row j-between a-center' >
                                            <Typography sx={{ color: "#fff" }} >Pitch:</Typography>
                                            <Slider
                                                sx={{ color: "#fff", }}
                                                aria-label="Pitch"
                                                //defaultValue={0}
                                                value={voiceReducer?.pitch}
                                                // getAriaValueText={(value) => `${value}%`}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => `${value}%`}
                                                disabled={voiceReducer?.is_generating_speech}
                                                // shiftStep={30}
                                                onChange={(e) => {
                                                    commonChangeHandler();
                                                    dispatch(updateVoiceState({ pitch: e.target.value }))
                                                }}
                                                step={10}
                                                marks
                                                min={-50}
                                                max={50}
                                            />
                                            <Box sx={{ width: '100px' }}>
                                                <Input
                                                    sx={{
                                                        marginBottom: 1.5,
                                                        padding: 0,
                                                        // backgroundColor:'white',
                                                        // borderRadius:'5px',
                                                        border: '1px solid #ffffff',
                                                        position: 'relative',
                                                        input: {
                                                            textAlign: 'center',
                                                            // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                                                            fontWeight: 600,
                                                            fontFamily: 'Rubik',
                                                            padding: 0,
                                                            color: '#ffffff',
                                                            // borderBottom:'1px solid #ffffff',
                                                            "&::placeholder": {    // <----- Add this.
                                                                // opacity:light == true ? 0.4 : 1,
                                                            },
                                                        }
                                                    }}
                                                    type="number"
                                                    //  min={-50}
                                                    // max={50}
                                                    disabled={voiceReducer?.is_generating_speech}
                                                    //defaultValue={0}
                                                    value={voiceReducer?.pitch}
                                                    onChange={(event, value) => {
                                                        commonChangeHandler()
                                                        dispatch(updateVoiceState({ pitch: (event.target.value >= -50 && event.target.value <= 50) ? event.target.value : voiceReducer?.pitch }))
                                                    }}
                                                //shiftMultiplier={10}
                                                />
                                            </Box>
                                        </Box>
                                        <Box width='100%' gap={3} className='flex row j-between a-center' >
                                            <Typography sx={{ color: "#fff" }} >Rate:</Typography>
                                            <Slider
                                                sx={{ color: "#fff" }}
                                                aria-label="Rate/Speed"
                                                //defaultValue={0}
                                                //aria-valuetext={`${voiceReducer?.rate} %`}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={(value) => `${value}%`}
                                                value={voiceReducer?.rate}
                                                onChange={(e) => {
                                                    commonChangeHandler();
                                                    dispatch(updateVoiceState({ rate: e.target.value }))
                                                }}
                                                //getAriaValueText={(value) => `${voiceReducer?.rate} %`}
                                                //valueLabelDisplay="auto"
                                                //shiftStep={30}
                                                step={10}
                                                disabled={voiceReducer?.is_generating_speech}
                                                marks
                                                min={-50}
                                                max={50}
                                            />
                                            <Box sx={{ width: '100px' }}>
                                                <Input
                                                    sx={{
                                                        marginBottom: 1.5,
                                                        // padding:0,
                                                        // backgroundColor:'white',
                                                        // borderRadius:'5px',
                                                        border: '1px solid #ffffff',
                                                        position: 'relative',
                                                        input: {
                                                            textAlign: 'center',
                                                            // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                                                            fontWeight: 600,
                                                            fontFamily: 'Rubik',
                                                            padding: 0,
                                                            color: '#ffffff',
                                                            // borderBottom:'1px solid #ffffff',
                                                            "&::placeholder": {    // <----- Add this.
                                                                // opacity:light == true ? 0.4 : 1,
                                                            },
                                                        }
                                                    }}
                                                    type="number"
                                                    //  min={-50}
                                                    // max={50}
                                                    disabled={voiceReducer?.is_generating_speech}
                                                    //defaultValue={0}
                                                    value={voiceReducer?.rate}
                                                    onChange={(event, value) => {
                                                        commonChangeHandler()
                                                        dispatch(updateVoiceState({ rate: (event.target.value >= -50 && event.target.value <= 50) ? event.target.value : voiceReducer?.rate }))
                                                    }}
                                                //shiftMultiplier={10}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className='flex row j-between a-center' sx={{my:4}} >
                                    <Box className='flex col j-center a-center' sx={{ gap: 2, mt: 1, mr: 3 }} >
                                        {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
                                        <CustomButton
                                             type='button'
                                            btnText = {voiceReducer?.is_sample_playing ? 'Pause Sample' : 'Play Sample'}
                                            style={{
                                                borderRadius: '5px',
                                               // border: "none",
                                               color: '#000000',
                                               border: '1px solid #00CEFF',
                                                textTransform: 'none',
                                                backgroundColor: '#00CEFF', 
                                            
                                            }}
                                            sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
               
                                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                            // color="secondary"
                                            onClick={playSampleHandler}
                                           // endIcon={<VolumeUpIcon style={{ fontSize: 18 }} />}
                                           endIcon={pendingCallReducer?.voice_sample ? <CircularProgress thickness={4} size={18} /> : voiceReducer?.is_sample_playing ? <PauseIcon style={{ fontSize: 16 }} /> : <PlayArrowIcon style={{ fontSize: 24 }} />}
                                           />  </Box>
                                    <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>
                                    <Box className='flex row j-end a-center' gap={2} sx={{ flex: 2, mt: 1 }} >
                                        {/* <Button variant='outlined' onClick={handleClose} style={{ borderRadius: '5px', backgroundColor: '#00D244', color: '#08231B', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '76px', sm: '76px', md: '76px', lg: '86px', xl: '129px' }, height: '32px', paddingY: 0.4, }}>Cancel</Button> */}
                                        {/* <Button variant='outlined' onClick={finishedHandler} style={{ borderRadius: '5px', backgroundColor: '#08231B', color: '#00D744', textTransform: 'none', }} sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, fontWeight: 600, width: { xs: '140px', md: '150px', xl: '169px' }, height: '32px', paddingY: 0.4, }}>{buttonState}</Button> */}
                                        {
                                            voiceReducer?.is_selected_default ? <Button 
                                                variant='outlined' 
                                                onClick={saveAsHandler} 
                                                style={{ borderRadius: '5px', backgroundColor: '#08231B', color: '#00D744', textTransform: 'none', }} 
                                                sx={{ fontSize: { xs: '9px', sm: '9px', md: '9px', lg: '11px', xl: '16px', }, 
                                                      fontWeight: 600, width: { xs: '140px', md: '150px', xl: '169px' }, 
                                                      height: '28px', 
                                                      paddingY: 0.4, 
                                                }}>Save As</Button>  :
                                                <CustomButtonlist 
                                                titles = {['Save', 'Save As']}
                                                tooltips = {['This will save these settings to current profile overwriting existing', `Save your modifications as a new voice profile, addingn it to 'My Voices' without altering the original`]}
                                                actions = {[saveHandler, saveAsHandler]}
                                                color = 'primary'
                                            />
                                        }
                                        
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </CustomCard>
            </Box>
        </Box>

        {/* </Box> */}

        {contentReducer.open_delete_popup && <DeleteConfirmationPopup
            open={contentReducer.open_delete_popup}
            closeHandler={() => dispatch(updateContentState({ open_delete_popup: false }))}
            deleteConfirmHandler={deleteProfileHandler}
            message='You are about to delete a voice profile. You will lose this settings for all the audio clips where it was used. Please type "delete" below to confirm.'
        />}

        {
            // commonReducer.play_tips_tricks_video == true && <PopupVideoPlayer tutorial />
        }

        {/* {scriptReducer.open_delete_popup && <DeleteConfirmationPopup
           // open={scriptReducer.open_delete_popup}
           // closeHandler={() => dispatch(updateSriptState({ open_delete_popup: false }))}
           // deleteConfirmHandler={deleteConfirmHandler}
            message='Are you sure you want to remove this project permanently? All the content related to this project will be erased. Please type "delete" below to confirm.'
        />} */}

    </AuthLayout>
}
export default VoiceProfileManager;