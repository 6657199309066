import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SunEditor, { buttonList } from "suneditor-react";
import plugins from 'suneditor/src/plugins'
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { updateContentState } from '../../../../redux/contentSlice';
import { updateVoiceState } from '../../../../redux/voiceSlice';
import { escapeForSSML, reduceWords, removeHtmlTags } from '../../../../utils/commonServices';

const CustomTextEditor = (props) => {
    const {initialContent, commonChangeHandler} = props;
    const editor = useRef();
    const dispatch = useDispatch()
    const contentReducer = useSelector((state) => state.contentReducer);
    // The sunEditor parameter will be set to the core suneditor instance when this function is called
    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };
    const is_xs = useMediaQuery('(max-width:599px)');
    const is_sm = useMediaQuery('(min-width:600px) and (max-width:959px)');
    const is_md = useMediaQuery('(min-width:960px) and (max-width:1279px)');
    const is_lg = useMediaQuery('(min-width:1280px) and (max-width:1919px)');
    const is_xl = useMediaQuery('(min-width:1920px)');
  // console.log("initial", initialContent)
   const handleChange = (content)=>{
   // console.log("content", removeHtmlTags(reduceWords(content, 30))); //Get Content Inside Editor
   console.log({content})
   !!commonChangeHandler && commonChangeHandler();

   dispatch(updateVoiceState({ sample_text: escapeForSSML(removeHtmlTags(reduceWords(content, 30)))}))
   dispatch(updateContentState({editor_text: content}))
    
    }

    // useEffect(()=> {
    //   dispatch(updateContentState({editor_text: initialContent}))
    // }, [])
    // console.log("content", contentReducer?.editor_text)
  return (
    <Box sx = {{ width: '100%', fontSize:{xs:'11px',md:'13px',xl:'18px'}, fontFamily:'Rubik',}} >
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        setAllPlugins= {false}
        width="100%"
        height={is_xs? "30vh": is_sm ? "30vh" : is_md ? "20vh": is_lg ? "30vh": is_xl ? "40vh" : "55vh"}
        setOptions={{
            resizeEnable: false,
            resizingBar: false,
            plugins: {...plugins},
            katex: "window.katex",
            buttonList: [
		[
			"undo",
			"redo",
			"font",
			"fontSize",
			//"formatBlock",
			//"paragraphStyle",
			//"blockquote",
			"bold",
			"underline",
			"italic",
			"strike",
			//"subscript",
			//"superscript",
			"fontColor",
			"hiliteColor",
			//"textStyle",
			"removeFormat",
			//"outdent",
			//"indent",
			"align",
			"horizontalRule",
			"list",
			//"lineHeight",
			//"table",
			//"link",
			//"image",
			//"video",
			//"audio",
			//"math",
			//"imageGallery",
			"fullScreen",
			//"showBlocks",
			"codeView",
			//"preview",
			//"print",
			//"save",
		//	"template"
		]
	],
          //  buttonList: [...buttonList.formatting], // Or Array of button list, eg. [['font', 'align'], ['image']]
            // plugins: [font] set plugins, all plugins are set by default
            // Other option
         
        }}
        setContents= {initialContent}
        onChange={handleChange} 
        
      />
    </Box>
  );
};
export default CustomTextEditor;