//import * as AWS from "aws-sdk";
import { S3, S3Client } from "@aws-sdk/client-s3";
import { useDispatch } from "react-redux";
import moment from 'moment-timezone';
import { resetUserState, updateUserState } from "../redux/userSlice";
import { resetScriptState } from "../redux/projectScriptSlice";
import { resetSnackbar, setSnackbar } from "../redux/messageSlice";
import { emptyDropdownArray } from "../redux/dropdownSlice";
import { resetProjectCreationState } from "../redux/projectCreationSlice";
import { videoLength } from "../constants/staticData";
import { resetCommonState, updateCommonState } from "../redux/commonSlice";


export const setSnackMessageType = (dataObject) => {
  const tempSnackObject = {flag: true, message: dataObject?.message}
  switch (dataObject?.http_code) {
    case 200: return {...tempSnackObject, type : "success"};
    case 404: ;
    case 422: ;
    case 401: ;
    case 400: ;
    default: return {...tempSnackObject, type : "error"};
  }
}

export const setInput = (value, type, pageData, setPageData) => {
  setPageData({ ...pageData, [type]: value });
};

export const getInitials = (str) => {
  
    var names = str.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;

}


export const afterValidate = (callBack) => {
  
  var errorMszDom = [];
  setTimeout(() => {
    errorMszDom = document.getElementsByClassName("errorDom");
    if (errorMszDom.length == 0) {
      // console.log("error not found")
      callBack();
    } else {
      // console.log('error', errorMszDom)
      // setSnakeBarProps({ snackbarFlag: true, msz: "Please fill all the required field", type: "error" })
    }
  });
};

export const timeStampToString = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  // var date = "";
  // if(a.getDate()%10 == 1 && a.getDate() != 11) {
  //     date=`${a.getDate()}st`
  // } else if(a.getDate()%10 == 2 && a.getDate() != 12 ) {
  //     date=`${a.getDate()}nd`
  // } else if(a.getDate()%10 == 3 && a.getDate() != 13 ) {
  //     date=`${a.getDate()}rd`
  // } else {
  //     date=`${a.getDate()}th`
  // }
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var dateString = date + ' ' + month + ' ' + year ;
  var dateString = month + " " + date + ", " + year;
  return dateString;
};

export const timeStampToDayString = (UNIX_timestamp, format) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var day = days[a.getDay()];
  // var date = "";
  // if(a.getDate()%10 == 1 && a.getDate() != 11) {
  //     date=`${a.getDate()}st`
  // } else if(a.getDate()%10 == 2 && a.getDate() != 12 ) {
  //     date=`${a.getDate()}nd`
  // } else if(a.getDate()%10 == 3 && a.getDate() != 13 ) {
  //     date=`${a.getDate()}rd`
  // } else {
  //     date=`${a.getDate()}th`
  // }
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var dateString = date + ' ' + month + ' ' + year ;

  if(format == "phone") {
    var dateString = day + ", " + month + " " + date + ", " + year;
  } else {
    var dateString = day + ", " + (a.getMonth()+1) + "/" + date + "/" + year;
  }
  
  return dateString;
};


export const timeStampToDateString = (UNIX_timestamp, format) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var day = days[a.getDay()];


  if(format == "phone") {
    var dateString = day + ", " + month + " " + date + ", " + year;
  } else {
    var dateString =   (a.getMonth()+1) + "/" + date + "/" + year;
  }
  
  return dateString;
};



export const isEventDate = (startTimeStamp, endTimeStamp) => {
 // const endTime = parseInt(endTimeStamp);
  const a = new Date(parseInt(startTimeStamp) * 1000);
  const b = new Date(parseInt(endTimeStamp) * 1000);
  const c = new Date();
  const startDate = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  const endDate = new Date(b.getFullYear(), b.getMonth(), (b.getDate()+1));
  const currenDate = new Date(c.getFullYear(), c.getMonth(), c.getDate())

  if( currenDate >= startDate && currenDate <= endDate) {
      return true;
  } else {
      return false
  }
  // const currenDate = Math.floor(Date.now() / 1000);
  // if (currenDate <= (endTime + (24 * 3600))) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const isEventPending = (startTimeStamp)=> {
  const a = new Date(parseInt(startTimeStamp) * 1000);
  const b = new Date();
  const startDate = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  const currenDate = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  if( currenDate < startDate) {
      return true;
  } else {
      return false
  }
}

export const isEventEnd = (endTimeStamp)=> {
  const a = new Date(parseInt(endTimeStamp) * 1000);
  const b = new Date();
  const endDate = new Date(a.getFullYear(), a.getMonth(), (a.getDate()+1));
  const currenDate = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  if( currenDate > endDate) {
      return true;
  } else {
      return false
  }
}

export const timeStampToNumberedDateString = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var dateString =
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date) +
    "-" +
    year;
  return dateString;
};
export const isEditStarted = (editStartTimestamp) => {
  const current = Date.now()/1000;
  if(current < parseInt(editStartTimestamp)) {
    return false
  } else {
    return true
  }
}

export const timeStampToDateTime = (UNIX_timestamp) => {
  var a = new Date(parseInt(UNIX_timestamp) * 1000);
  var year = a.getFullYear();
  var month = a.getMonth() + 1;
  var date = a.getDate();
  var dateString =
    (month < 10 ? "0" + month : month) +
    "-" +
    (date < 10 ? "0" + date : date) +
    "-" +
    year;
  // return a.toLocaleString() ;
  const dateTimeString = a.toLocaleString();

  const stringWithoutSeconds = dateTimeString.substring(
    0,
    dateTimeString.lastIndexOf(":")
  );
  return stringWithoutSeconds;
};


 export const timeStampToFormatTimeZone = (UNIX_timestamp) => {
  const date = new Date(parseInt(UNIX_timestamp) * 1000);
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneOffset = date.getTimezoneOffset();
  const timeZone = moment.tz.zone(timeZoneId).abbr(timeZoneOffset)
  
  return `${formattedTime} (${timeZone})`;
};



export const base64ToBlob = async (base, name) => {
  const imageName = name.substring(0, name.lastIndexOf(".")) + ".png";

  const base64Response = await fetch(base);
  // const blob = URL.createObjectURL(base64Response)
  const blob = await base64Response.blob();
  const myFile = new File([blob], imageName, {
    type: blob.type,
  });
  return myFile;

  //   if(!file) {
  //     setBlob('');
  //     return;
  //   }
  // just use the URL.createObjectURL to convert to blob
  //   setBlob(URL.createObjectURL(file))
  //}

  //     var url = base

  //    return fetch(url)
  //     .then(res => res.blob())
};

export const removeDuplicateArrayItem = (arr, key) => {
  const uniqueArray = arr.filter(
    (object, index) =>
      index == arr.findIndex((obj) => obj[key] == object[key])
  );
  return uniqueArray;
};

export const isAcceptedFormat = (fileName) => {
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  if (
    extension.toUpperCase() == "MP4" ||
    extension.toUpperCase() == "MOV" ||
    extension.toUpperCase() == "WEBM" ||
    extension.toUpperCase() == "OGG"
  ) {
    return true;
  } else {
    return false;
  }
};

export const fileFormat = (fileName) => {
  const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return extension
};
export const removeExtension = (fileName) => {
const [nameWithoutExtension] = fileName?.split('.');
return nameWithoutExtension; // Output: "example"
};

export const sizeConverter = (bytes) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const  isIos = ()=> {
  var iOS = 
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream;
  return iOS;
}

export const redirectToApp = (appId, queryString) => {
  if (isIos()){
    window.location.href = (`https://apps.apple.com/app/${appId}?${queryString}`)
   } else {
    window.location.href = "/";
   }
}

export const openInNewTab = (url) => {
  // const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  const pdfWindow = window.open();
  pdfWindow.location.href = 'https://s3.us-central-1.wasabisys.com/files-storage-staging/final_video/2/wasabi_sample.mp4'
}

export const getFileNameFromUrl= (url)=> {
  const regex = /\/([^/]+\.\w{3,4})(\?.*)?$/;
  const match = url?.match(regex);
  return match ? match[1] : null;
}


export const downloadS3File = url => {
  //const s3Url = 'https://your-s3-bucket.s3.amazonaws.com/your-video.mp4';
  
  const fileName = getFileNameFromUrl(url);

 
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
 
  // fetch(s3Url)
  // .then(response => response.blob())
  // .then(blob => {
  //   const url = window.URL.createObjectURL(new Blob([blob]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', videoName);
  //   document.body.appendChild(link);
  //   link.click();
  // });
  
  
  
}

export const getFileType = (fileName) => {

  const fileExtension = fileFormat(fileName).toLowerCase();

  switch (fileExtension) {
    case 'mp4':
    case 'mov':
    case 'ogg':
      return 'video';
    case 'mp3':
    case 'wav':
      return 'audio';
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'webp':
      return 'image';
    default:
      return 'unknown';
  }
};

export const dateCountdown = (UNIX_timestamp) => {
 
    const now = new Date().getTime();
    const futureDate = new Date(parseInt(UNIX_timestamp) * 1000).getTime();
    // console.log('now ', now)
    // console.log('futureDate ', futureDate)
    const daysRemaining = futureDate - now
    // console.log(daysRemaining)
    const days = Math.floor(daysRemaining / (1000 * 60 * 60 * 24));
    if (days < 1) {
      // setEditingStartsIn(0)
      return 0
    }
 
    return days

};

export const groupArrayByValue = (arr, value)=> {
  const groupedArrays = {};
  
  arr.forEach(item => {
    const key = item[value];  // Assuming each item is an object and you want to group by a specific key
    if (!groupedArrays[key]) {
      groupedArrays[key] = [];
    }
    groupedArrays[key].push(item);
  });
  
  return Object.values(groupedArrays);
}

export const sortArrayByCategory = (array, order) => {
 // const order = { 'a': 1, 'b': 2, 'c': 3 };
  
  return array?.sort((obj1, obj2) => {
    return order[obj1.category] - order[obj2.category];
  });
};



export const functionFormatTime = (seconds)=>{
  const date = new Date(seconds * 1000);
 const hh = date.getUTCHours().toString().padStart(2, '0');
 const mm = date.getUTCMinutes().toString().padStart(2, '0');
 const ss = date.getUTCSeconds().toString().padStart(2, '0');

 if(hh == 0){
   return `${mm}:${ss}`
 }else{
   return `${hh}:${mm}:${ss}`;
 }
 }


 export function getHeaderForAPICall(headerToMerge) {
  const defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  let headersToMerge = {};
  if (typeof headerToMerge === 'object') headersToMerge = headerToMerge;
  return Object.assign({}, defaultHeader, headersToMerge);
}

export function convertJsonToQS(dataToPost) {
  return Object.keys(dataToPost)
    .map(key => `${key}=${dataToPost[key]}`)
    .join('&');
}

export const hasPermission = (userPermissions, requiredPermission) => {
  return userPermissions.includes(requiredPermission);
};

export const formatDynamicDate = (isoDateString) => {
  if( isoDateString == null || isoDateString == undefined ){
    return
  }
  const date = new Date(isoDateString);

  // Define options for formatting the date
  const options = {
    weekday: 'long',   // Full weekday name (e.g., "Friday")
    day: '2-digit',    // Two-digit day of the month (e.g., "20")
    month: '2-digit',  // Two-digit month (e.g., "09")
    year: 'numeric',   // Numeric year (e.g., "2023")
  };


  const dateFormatter = new Intl.DateTimeFormat(undefined, options);
  const formattedDate = dateFormatter.format(date);

  return formattedDate;
};


export const resetAllState = (dispatch)=> {
  dispatch(resetCommonState());
  dispatch(resetUserState());
  dispatch(emptyDropdownArray());
  dispatch(resetSnackbar());
  dispatch(resetProjectCreationState());
  dispatch(resetScriptState());
};

export const logout = (dispatch, navigate)=> {
  dispatch(updateCommonState({ logout_all: true }));
  window.localStorage.removeItem("token");
  dispatch(updateUserState({is_logged: false}))
  navigate('/redirect')
 // const params = new URLSearchParams({  redirect: true }).toString();
 // window.location.href = `${process.env.REACT_APP_LUCIHUB_URL}/signin?${params}`;
 
}

export const showSnackBar = (dataObject, dispatch)=> {
  var httpCode;
  if(!!dataObject?.status) {
    httpCode = dataObject?.status
  } else {
    httpCode = dataObject?.httpCode
  }
  var tempSnackObject = {flag: true, message: dataObject?.message}

 // const httpCode = !!dataObject?.http_code ? dataObject?.http_code : dataObject?.httpCode
  //console.log({httpCode})
  switch (httpCode) {
    case 200:  
    case 201:  dispatch(setSnackbar({...tempSnackObject, type : "success"}));
               break;
    case 401:  logout(dispatch);
               dispatch(setSnackbar({...tempSnackObject, message: "Session expired!", type : "error"}));
               break;
    case 404: ; dispatch(setSnackbar({...tempSnackObject, type : "error"}));
                break;
    case 422: ;
    case 400: ;
    case 500: dispatch(setSnackbar({...tempSnackObject, type : "error"}));
              break;
    default: dispatch(setSnackbar({ flag: false, message: "", type : "success"}));
  }
}

export const showAuthSnackBar = (dataObject, dispatch)=> {
  const tempSnackObject = {flag: true, message: dataObject?.message}
  const httpCode = !!dataObject?.http_code ? dataObject?.http_code : dataObject?.httpCode
  //console.log({httpCode})
  switch (httpCode) {
    case 200:  
    case 201:  dispatch(setSnackbar({...tempSnackObject, type : "success"}));
               break;
    case 401:  logout(dispatch);
               dispatch(setSnackbar({...tempSnackObject, message: "Session expired!", type : "error"}));
               break;
    case 404: ;
    case 422: ;
    case 400: ;
    case 500: dispatch(setSnackbar({...tempSnackObject, type : "error"}));
              break;
    default: dispatch(setSnackbar({...tempSnackObject, flag: false, type : "success"}));
  }
}

export const getNamesFromIds = (id, arr) => {
  if (arr?.length > 0 && arr?.find((item)=> item.id == id) ) {
      return arr?.find((item)=> item.id == id)
  } else {
      return {}
  }
};

export const removeWhiteSpaces = (inputString)=> {
  let stringWithoutSpaces
  if(!!inputString) {
    stringWithoutSpaces = inputString.replace(/\s/g, '');
  }
  return stringWithoutSpaces
}

export const isArray= (myArray)=> {
  return !!myArray ? myArray.constructor === Array : false;
}

const htmlEntities = {
  '&nbsp;': ' ',
  '&amp;': '&',
  '&quot;': '"',
  '&apos;': "'",
  '&lt;': '<',
  '&gt;': '>',
  // Add more entities as needed
};

export const removeHtmlTags = (str) => {
  if (!str || typeof str !== 'string') return '';

  // Remove the title in <b></b> tag followed by \n if it is at the start of the string
  str = str.replace(/^\s*(<b>.*?<\/b>|<strong>.*?<\/strong>)\s*(<br>\s*|\n)/, '');
  
 // Replace newline characters with a long whitespace
 str = str.replace(/<br>/g, '. ').replace(/\n/g, '. ');

  // Remove all HTML tags
  str = str.replace(/<[^>]*>/g, '');

  // Replace HTML entities
  str = str.replace(/&[^;]+;/g, (entity) => {
    return htmlEntities[entity] || entity;
  });
   
  console.log("removed tags", str)
  return str;
};

export const escapeForSSML = (text) => {
  if (!text) return '';

  return text
    .replace(/&(?!(amp|lt|gt|quot|apos);)/g, '&amp;')  // Escapes & only if it's not part of an existing escape sequence
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
};
// export const removeHtmlTags = (str) => {
//   if (!str || typeof str !== 'string') return '';
//   return str.replace(/<[^>]*>/g, '');
// };

export const copyToClipboardHandler = (str, callBack)=> {
  navigator.clipboard.writeText(str)
  callBack && callBack();
}

export const handleImageDownload =(url, name)=>{
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
}

 export const capitalizeFirstLetter = (string) => {

  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

// Count words in a string

export const countWords = (text)=> {
  // Remove extra whitespaces and trim the text
  let cleanText = text.trim().replace(/\s+/g, ' ');

  // Split the text into words based on spaces
  let words = cleanText.split(' ');

  // Count the number of words
  let wordCount = words.length;

  return wordCount;
}

export const calculateSpeakingTime = (text, wordsPerMinute = 120) => {
  // Count the number of words in the text
  let wordCount = countWords(text);

  // Calculate the time required to speak the words at the given rate
  let speakingTimeMinutes = wordCount / wordsPerMinute;
  let speakingTimeSeconds = speakingTimeMinutes * 60;

  // Define options for rounding

  const timeOptions = videoLength?.map(item=> {
    return item.value;
  })

  // Find the option closest to the calculated time in seconds
  let closestOption = timeOptions.reduce((prev, curr) => {
    return (Math.abs(curr - speakingTimeSeconds) < Math.abs(prev - speakingTimeSeconds) ? curr : prev);
  });

  return {
    wordCount: wordCount,
    speakingTime: closestOption
  };
}

export const reduceWords = (text, wordLimit) => {
   // Split the input string into words
   const words = text.split(/\s+/);

   // Check if the number of words is less than or equal to 30
   if (words.length <= 30) {
       return text;
   }

   // Join the first 30 words and return the result
   return words.slice(0, wordLimit).join(' ');
}

export const getFirstWord = (sentence)=> {
  if (!sentence) {
    return '';
  }

  const words = sentence.trim().split(/\s+/);
  return words[0];
}

export const createQueryParams = (params) => {
  const searchParams = new URLSearchParams();

  Object.keys(params).forEach(key => {
      if (params[key] !== undefined && params[key] !== null) {
          searchParams.append(key, params[key]);
      }
  });

  return searchParams.toString();
};



export const findKeyAnywhereInJson = (obj, keyToFind)=> {
  const idValues = [];

  function traverse(value) {
      if (typeof value === 'object' && value !== null) {
          if (Array.isArray(value)) {
              for (const item of value) {
                  traverse(item);
              }
          } else {
              for (const key in value) {
                  if (key === keyToFind) {
                      idValues.push(value[key]);
                  } else {
                      traverse(value[key]);
                  }
              }
          }
      }
  }

  traverse(obj);
  return idValues;
}









