import { Box, CircularProgress, IconButton, Input, Slider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import CustomAutocomplete from "../../../eventcreation/components/CustomAutoComplete";
import CustomTooltip from "../../../../components/CustomTooltip";
import QuantityInput from "../../../../components/QuantityInput";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useDispatch, useSelector } from "react-redux";
import { updateVoiceState } from "../../../../redux/voiceSlice";
import { azureLanguageNames } from "../../../../constants/constants";
import CustomButton from "../../../../components/CustomButton";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import CustomInput from "../../../eventcreation/components/CustomInput";
import { escapeForSSML, getFirstWord, groupArrayByValue, reduceWords, removeHtmlTags, sortArrayByCategory } from "../../../../utils/commonServices";
import CustomGroupedAutoComplete from "../../../eventcreation/components/CustomGroupedAutoComplete";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const AdvancedSettingView = (props) => {
    const { audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech,
        sampleRef, handleGenerateVoiceSample, pauseSample, playSample, generateSpeechClickHandler,
        playSampleHandler, commonChangeHandler } = props
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const voiceReducer = useSelector((state) => state.voiceReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer)
    const dispatch = useDispatch();

    const saveProfileHandler = () => {
        commonChangeHandler();
        dispatch(updateVoiceState({ voice_profile_creation_popup: true }))
    }

    useEffect(()=> {
        dispatch(updateVoiceState({
           // profile_name: "", 
            //profile_description: "",
            //voice_profile_view: false,  
            sample_text: escapeForSSML(removeHtmlTags(reduceWords(contentReducer?.editor_text, 30))),
           // selected_voice_profile: 0
          }))
    }, [contentReducer?.editor_text])
    
    //Category description statics------------------------------------------------------------------------------

    const categoryDescription = {
        'Global Accents' : "Choose voices with various accents to add cultural distinction to your content.",
        "Versatile Tones" : "Select voice styles to match the desired tone and mood of your content.",
        "Standard Voices" : "Clear and straightforward voices without accents or style variations."
    }
    const options = sortArrayByCategory(dropdownReducer?.voices?.length > 0 && dropdownReducer?.voices?.map((item)=> {
        return {...item, category: item.SecondaryLocaleList?.length > 0 ? "Global Accents" : item.StyleList?.length > 0 ? "Versatile Tones" : "Standard Voices"}
    }), {'Global Accents' : 1, "Versatile Tones" : 2, "Standard Voices" : 3})

    // const options = dropdownReducer?.voices?.length > 0 && dropdownReducer?.voices?.map((item)=> {
    //     return {...item, category: item.SecondaryLocaleList?.length > 0 ? "accents" : dropdownReducer?.voices?.StyleList?.length > 0 ? "styles" : "regular"}
    // })

    console.log("voices list", options)

    return <Box className='flex row j-between a-start' mt={1.2} >
        {/* <ScriptInputBox/> */}
        <Box className='flex col j-start a-start'  >
            <Box className="flex col j-center a-start" sx={{gap: 1}} >
                {
                    dropdownReducer?.voices?.length > 0 && <Box className='flex row j-between a-center' sx={{gap:1, }}  >
                    <CustomGroupedAutoComplete
                        id="voices"
                       // options={dropdownReducer?.voices}
                       options={options}
                        sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                        headerTooltip = {categoryDescription}
                        // onFocus={() => {
                        //   setScriptLengthValidationFlag(false)
                        // }}
                        // onBlur={() => setExportState({enable: false, formatId: ''})}
                        // required
                        // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                        // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                        // boldError
                        value={!!options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) ?  options?.find(item => item.ShortName == voiceReducer?.selected_voice?.ShortName) : options[5]}
                        disabled={voiceReducer?.is_generating_speech}
                        onChange={(event, selection) => {
                            //!!voiceReducer?.is_sample_playing && pauseSample();

                            commonChangeHandler()
                            dispatch(updateVoiceState({
                                selected_style: null,
                                selected_accent: null,
                                selected_voice: selection
                            }))
                            !!selection?.StyleList ? dispatch(updateVoiceState({ styles: ["default", ...selection?.StyleList] })) : dispatch(updateVoiceState({ styles: [] }))
                            !!selection?.SecondaryLocaleList ? dispatch(updateVoiceState({ accents: selection?.SecondaryLocaleList })) : dispatch(updateVoiceState({ accents: [] }))

                        }}
                        label=""
                        placeholder='Select Voice'
                        groupBy={(option) => option.category}
                        getOptionLabel={(option) =>
                            `${option.DisplayName} - ${option.LocaleName} - ${option.Gender}`
                        }   
                        // renderGroup={(params) => (
                        //     <li key={params.key}>
                        //        <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{params.group}</Typography>
                        //        <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{params.children}</Typography>
                        //     </li>
                        //   )}
                        renderOption={(props, option) => {
                            return <Box {...props} className='flex col j-start' mb={1.5} ml={1} sx={{borderBottom: '1px solid #cccccc', pb: 0.5}} >
                                <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{`${getFirstWord(option.DisplayName)} | ${option.Gender}`}</Typography>
                                {
                                    option.SecondaryLocaleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                       {`Available in `} <Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.SecondaryLocaleList?.length}</Typography>{option.SecondaryLocaleList?.length > 1 ? ` different accents` : `accent`}
                                    </Typography> : option.StyleList?.length > 0 ? <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>
                                    {`Available in `}<Typography component='span' sx={{ fontSize: 12, fontWeight: 600, color: '#1a2721' }}>{option.StyleList?.length}</Typography>{option.StyleList?.length > 1 ? ` different styles` : ` style` }
                                    </Typography> : <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>{option.LocaleName}</Typography>
                                }
                                
                                
                            </Box>
                        }}    
                        msgColor='red'
                    />
                        <CustomTooltip title="Save these settings as a voice profile." >
                        <IconButton
                        style={{padding: 1, marginLeft: 3, marginRight: 3}}
                            //  disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={saveProfileHandler}
                        >
                            <SaveAsIcon style={{ color: '#ffffff', fontSize: '32px' }} />
                        </IconButton>
                    </CustomTooltip>

                </Box>
                }
                
                {
                    voiceReducer?.styles?.length > 0 && <Box >
                        <CustomAutocomplete
                            id="style_list"
                            sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                            disabled={voiceReducer?.styles?.length == 0}
                            options={voiceReducer?.styles}
                            value={voiceReducer?.selected_style}
                            onChange={(event, selection) => {
                                commonChangeHandler()
                                dispatch(updateVoiceState({
                                    selected_accent: null,
                                    selected_style: selection
                                }))
                            }}
                            label=""
                            placeholder='Select Tone'
                            getOptionLabel={(option) =>
                                `${option}`
                            }
                            msgColor='red'
                        />
                   
                    </Box>
                }
                {
                    voiceReducer?.accents?.length > 0 && <Box my={1} >
                        <CustomAutocomplete
                            id="accent_list"
                            sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, }}
                            disabled={voiceReducer?.accents?.length == 0}
                            options={voiceReducer?.accents}
                            value={voiceReducer?.selected_accent}
                            onChange={(event, selection) => {
                                commonChangeHandler();
                                dispatch(updateVoiceState({
                                    selected_accent: selection,
                                }))
                            }}
                            label=""
                            placeholder='Select Accent'
                            getOptionLabel={(option) =>
                                `${!!azureLanguageNames[option] ? azureLanguageNames[option] : ""}`
                            }
                            msgColor='red'
                        />
                    </Box>
                }
                {/* <Box className='flex row j-start a-center' sx={{ minWidth: { sm: '200px', md: '260px', lg: '270px' }, mt: 1 }} >
                    <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} >
                        <IconButton
                            style={{padding: 1, marginLeft: 1, marginRight: 3}}
                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                            onClick={playSampleHandler}
                        >
                            <VolumeUpIcon style={{ color: voiceReducer?.is_sample_playing ? '#00ceff' : '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title="Save these settings as a voice profile." >
                        <IconButton
                        style={{padding: 1, marginLeft: 3, marginRight: 3}}
                            //  disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={saveProfileHandler}
                        >
                            <SaveIcon style={{ color: '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                    </CustomTooltip>

                    <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>
                </Box> */}
            </Box>
        </Box>
        <Box className='flex col j-center' sx={{ gap: 2, mb: 1, width: '45%', }} >
            <Box width='100%' gap={3} className='flex row j-center a-center' >
                <Typography sx={{ color: "#fff" }} >Pitch:</Typography>
                <Slider
                    sx={{ color: "#fff",display: {xs: 'none', lg: 'initial'} }}
                    aria-label="Pitch"
                    //defaultValue={0}
                    value={voiceReducer?.pitch}
                    // getAriaValueText={(value) => `${value}%`}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                    disabled={voiceReducer?.is_generating_speech}
                    // shiftStep={30}
                    onChange={(e) => {
                        commonChangeHandler();
                        dispatch(updateVoiceState({ pitch: e.target.value }))
                    }}
                    step={10}
                    marks
                    min={-50}
                    max={50}
                />
                <Box sx={{width: '100px'}}>
                <Input
                     sx={{
                         marginBottom: 1,
                         padding:0,
                        // backgroundColor:'white',
                       // borderRadius:'5px',
                       border: '1px solid #ffffff',
                        position:'relative',
                        input: {
                          textAlign: 'center',
                         // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                          fontWeight: 600,
                          fontFamily: 'Rubik',
                          padding: 0,
                          color:'#ffffff',
                          // borderBottom:'1px solid #ffffff',
                          "&::placeholder": {    // <----- Add this.
                           // opacity:light == true ? 0.4 : 1,
                        }, 
                    }
                    }}    
                    type="number"
                  //  min={-50}
                   // max={50}
                    disabled={voiceReducer?.is_generating_speech}
                    //defaultValue={0}
                    value={voiceReducer?.pitch}
                    onChange={(event, value) => {
                        commonChangeHandler()
                        dispatch(updateVoiceState({ pitch: (event.target.value >= -50 && event.target.value <=50) ? event.target.value : voiceReducer?.pitch }))
                    }}
                    //shiftMultiplier={10}
                />
                </Box>
            </Box>
            <Box width='100%' gap={3} className='flex row j-center a-center' >
                <Typography sx={{ color: "#fff" }} >Rate:</Typography>
                <Slider
                    sx={{ color: "#fff", display: {xs: 'none', lg: 'initial'} }}
                    aria-label="Rate/Speed"
                    //defaultValue={0}
                    //aria-valuetext={`${voiceReducer?.rate} %`}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}%`}
                    value={voiceReducer?.rate}
                    onChange={(e) => {
                        commonChangeHandler();
                        dispatch(updateVoiceState({ rate: e.target.value }))
                    }}
                    //getAriaValueText={(value) => `${voiceReducer?.rate} %`}
                    //valueLabelDisplay="auto"
                    //shiftStep={30}
                    step={10}
                    disabled={voiceReducer?.is_generating_speech}
                    marks
                    min={-50}
                    max={50}
                />
                <Box sx={{width: '100px'}}>
                <Input
                     sx={{
                         marginBottom: 1,
                        // padding:0,
                        // backgroundColor:'white',
                       // borderRadius:'5px',
                       border: '1px solid #ffffff',
                        position:'relative',
                        input: {
                          textAlign: 'center',
                         // fontSize: medium ?  {xs:'12px',lg:'13px',xl:'18px'} : {xs:'8px',lg:'9px',xl:'13px'},
                          fontWeight: 600,
                          fontFamily: 'Rubik',
                          padding: 0,
                          color:'#ffffff',
                          // borderBottom:'1px solid #ffffff',
                          "&::placeholder": {    // <----- Add this.
                           // opacity:light == true ? 0.4 : 1,
                        }, 
                    }
                    }}    
                    type="number"
                  //  min={-50}
                   // max={50}
                    disabled={voiceReducer?.is_generating_speech}
                    //defaultValue={0}
                    value={voiceReducer?.rate}
                    onChange={(event, value) => {
                        commonChangeHandler()
                        dispatch(updateVoiceState({ rate: (event.target.value >= -50 && event.target.value <=50) ? event.target.value : voiceReducer?.rate }))
                    }}
                    //shiftMultiplier={10}
                />
                </Box>
            </Box>
        </Box>
        <Box className='flex' sx={{flexDirection:{xs: 'column'}, justifyContent: {xs:'center'}, alignItems: 'center', gap: 2, mr: 3 }} >
            {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
            <CustomButton
                                             type='button'
                                            btnText = {voiceReducer?.is_sample_playing ? 'Pause Sample' : 'Play Sample'}
                                            style={{
                                                borderRadius: '5px',
                                               // border: "none",
                                               color: '#000000',
                                               border: '1px solid #00CEFF',
                                                textTransform: 'none',
                                                backgroundColor: '#00CEFF', 
                                            
                                            }}
                                            sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
               
                                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                            // color="secondary"
                                            onClick={playSampleHandler}
                                           // endIcon={<VolumeUpIcon style={{ fontSize: 18 }} />}
                                           endIcon={pendingCallReducer?.voice_sample ? <CircularProgress thickness={4} size={18} /> : voiceReducer?.is_sample_playing ? <PauseIcon style={{ fontSize: 16 }} /> : <PlayArrowIcon style={{ fontSize: 24 }} />}
                                           />
              <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>    
                                    
            <CustomButton
                btnText={voiceReducer?.is_generating_speech ? 'Please wait...' : 'Create Voice Clip'}
                toolTipTitle = 'Generate voice clip using selected voice profile and script settings.'
                // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                onClick={generateSpeechClickHandler}
                disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || !voiceReducer?.selected_voice || contentReducer?.editor_text == ""}
                type='button'
                style={{
                    borderRadius: '5px',
                    border: "none",
                    textTransform: 'none',
                    backgroundColor: '#08231B', 
                    color: '#00D744',
                }}
                sx={{ fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' }, minWidth: '142px', }}
                />
         
        </Box>
    </Box>
}

export default AdvancedSettingView;