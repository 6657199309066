import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomAutocomplete from "../../../eventcreation/components/CustomAutoComplete";
import CustomTooltip from "../../../../components/CustomTooltip";
import CustomButton from "../../../../components/CustomButton";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { updateVoiceState } from "../../../../redux/voiceSlice";
import { escapeForSSML, reduceWords, removeHtmlTags } from "../../../../utils/commonServices";
import useVoiceProfileActions from "../voiceProfile/hooks/useVoiceProfileActions";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { useNavigate } from "react-router-dom";
import DeleteConfirmationPopup from "../../../dashboard/components/DeleteConfirmationPopup";
import { updateContentState } from "../../../../redux/contentSlice";
import LockIcon from '@mui/icons-material/Lock';
import SimpleAlert from "../../../../components/SimpleAlert";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { CircularProgress } from '@mui/material';

const VoiceProfileView = (props) => {
    const { audioRef, handleConvertTextToSpeech, pauseSpeech, playSpeech,
        sampleRef, handleGenerateVoiceSample, pauseSample, playSample, generateSpeechClickHandler,
        playSampleHandler, commonChangeHandler } = props
        const [reRender, setReRender] = useState(true)
    const dropdownReducer = useSelector((state) => state.persistedReducers.dropdownReducer);
    const voiceReducer = useSelector((state) => state.voiceReducer);
    const contentReducer = useSelector((state) => state.contentReducer);
    const pendingCallReducer = useSelector((state) => state.pendingCallReducer)
    const [showToolbar, setShowToolbar] = useState(false)
    const [isProfileDeleted, setIsProfileDeleted] = useState(false)
    const { getVoiceProfiles, deleteVoiceProfile } = useVoiceProfileActions();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        getVoiceProfiles();
    }, [])

    useEffect(() => {
        if(!voiceReducer?.voice_profile_creation_popup && voiceReducer?.voice_profiles?.length > 0) {
           
            const selectedProfile =  voiceReducer?.voice_profiles?.find(item => (item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile))

            if(!!selectedProfile) {
                onProfileSelection(selectedProfile)
            } else {
                if(!!voiceReducer?.selected_voice_profile && !!voiceReducer?.audio_url) {
                    setIsProfileDeleted(true)
                } else {
                    setIsProfileDeleted(false)
                }
                onProfileSelection(voiceReducer?.voice_profiles[0])  
            }


            
        }
       
    }, [voiceReducer?.voice_profile_creation_popup, voiceReducer?.voice_profiles, voiceReducer?.voice_profile_view])
    console.log("after popup close", sampleRef.current?.src)
    // useEffect(()=> {
    //     if(!!voiceReducer?.selected_voice_profile){
    //     onProfileSelection(voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile))
    //     }
    // }, [voiceReducer?.selected_voice_profile, voiceReducer?.is_selected_default])

    const onProfileSelection = (selection)=> {
        dispatch(updateVoiceState({
            profile_name: selection?.name,
            profile_description: selection?.description,
            selected_style: selection?.style,
            selected_accent: selection?.voice_accent,
            selected_voice: dropdownReducer?.voices?.find(item => item.ShortName == selection?.voice),
            pitch: selection?.voice_pitch,
            rate: selection?.voice_speed,
           // sample_text: (!!selection?.script && selection?.is_default_profile == 1) ? selection?.script : removeHtmlTags(reduceWords(contentReducer?.editor_text, 30)),
           sample_text: escapeForSSML(removeHtmlTags(reduceWords(contentReducer?.editor_text, 30))),
            selected_voice_profile: selection?.id,
            is_selected_default: !!selection?.is_default_profile
        }))
    }
    const editProfileHandler = ()=> {
        commonChangeHandler();
        dispatch(updateVoiceState({
                profile_name: voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile)?.name , 
                profile_description: voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile)?.description, 
                is_profile_edit: true,
                voice_profile_creation_popup: true 
            }))
    }

    const createProfileHandler = ()=> {
        commonChangeHandler();
        dispatch(updateVoiceState({profile_name: "", profile_description: "", voice_profile_creation_popup: true }))
    }

    const deleteProfileHandler = ()=> {
        deleteVoiceProfile({id: voiceReducer?.selected_voice_profile}, ()=> {
           
            onProfileSelection(voiceReducer?.voice_profiles[0])
        })//api call
    }
    const manageProfileHandler = ()=> {
        commonChangeHandler();
       navigate('/voiceprofiles');
    }

    return <Box  >
        {/* <ScriptInputBox/> */}
        <Box className='flex row j-between a-start' mt={1.2}  >
            <Box className="flex col j-center a-start"  >
                
                    
                    {
                       voiceReducer?.voice_profiles?.length > 0 &&
                       <Box className='flex row j-between a-center' sx={{gap:1, my: {xs: 2, lg: 0}}}  >
                       <CustomAutocomplete
                        id="voices"
                        toolTipTitle='Browse the Library of Default Voices designed to provide a solid foundation for your projects.'
                        options={voiceReducer?.voice_profiles}
                        sx={{ minWidth: { xs: '350px', md: '460px', lg: '590px', xl: '900px' }, }}
                        // medium
                        fullWidth
                        // onFocus={() => {
                            //   setScriptLengthValidationFlag(false)
                            // }}
                            // onBlur={() => setExportState({enable: false, formatId: ''})}
                            // required
                            // errorFlag={scriptLengthValidationFlag && getErrorMsg("script_length", projectLength, exportAs?.id) != ""}
                            // errorMsg={getErrorMsg("script_length", projectLength, exportAs?.id)}
                            // boldError
                            value={
                                !!voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile) ?
                                    voiceReducer?.voice_profiles?.find(item => item.id == voiceReducer?.selected_voice_profile && voiceReducer?.is_selected_default == !!item.is_default_profile) :
                                        voiceReducer?.voice_profiles[0]
                            }
                            disabled={voiceReducer?.is_generating_speech}
                            onChange={(event, selection) => {
                                //!!voiceReducer?.is_sample_playing && pauseSample();
                                
                                commonChangeHandler()
                                console.log("selection", selection)
                                onProfileSelection(selection)
                            }}
                            label=""
                            placeholder='Select Voice'
                            getOptionLabel={(option) =>
                                `${option.name}: ${option.description}`
                            }
                            renderOption={(props, option) => {
                                return <Box {...props} className='flex row j-between a-center' mb={1.5} ml={1} sx={{borderBottom: '1px solid #cccccc', pb: 0.5}}>
                                <Box  className='flex col j-start' sx={{flex: 1}} >
                                <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#000000' }}>{option.name}</Typography>
                                <Typography sx={{ fontSize: 12, fontWeight: 300, color: '#000000' }}>{option.description}</Typography>    
                                </Box>
                              { /* option.is_default_profile ?  <Box>
                                   <LockIcon style={{fontSize: '18px'}} />
                            </Box> : <></> */}
                            </Box>
                        }}
                        msgColor='red'
                        />
                             <CustomTooltip title='Go to voice profiles manager'>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                        // disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={manageProfileHandler} 
                        >
                            <SettingsIcon sx={{ color: '#ffffff', fontSize: '32px', marginTop: {xs: '8px', md: '0px'}, marginBottom: {xl: '8px'} }}/>
                        </IconButton>
                    </CustomTooltip> 
                </Box>
                    }
               
                {/* <Box className='flex row j-start a-center' my={1} >
                    <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} >
                        <IconButton
                            style={{ marginLeft: 1, marginRight: 3, padding: 0 }}
                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                            onClick={playSampleHandler}
                        >
                            <VolumeUpIcon style={{ color: voiceReducer?.is_sample_playing ? '#00ceff' : '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                    </CustomTooltip> */}
                   {/* <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>*/}
                    {/* <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#ffffff', mx:1 }} >|</Typography> */}
                    {/* {
                        showToolbar ? <> 
                           <CustomTooltip title='Hide voice profile management options'>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                        // disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={()=> setShowToolbar(false)} 
                        >
                            <ChevronLeftIcon style={{ color: '#ffffff', fontSize: '28px' }} />
                        </IconButton>
                    </CustomTooltip>*/}
                    {/* <CustomTooltip title='Create new voice profile'>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                        // disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={createProfileHandler} 
                        >
                            <AddBoxIcon style={{ color: '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                    </CustomTooltip>
                             <CustomTooltip title={ voiceReducer?.is_selected_default ? 'Not allowed to edit default profiles' : 'Edit this voice profile' }>
                                <span>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                            disabled={voiceReducer?.is_selected_default}
                            onClick={editProfileHandler} 
                        >
                            <EditRoundedIcon style={{ color: voiceReducer?.is_selected_default ? '#888888' : '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                        </span>
                    </CustomTooltip>
                    
                    <CustomTooltip title={voiceReducer?.is_selected_default ? 'Not allowed to delete default profiles' : 'Delete this voice profile'}>
                        <span>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                            disabled={voiceReducer?.is_selected_default}
                            onClick={() => dispatch(updateContentState({ open_delete_popup: true }))} 
                        >
                            <DeleteForeverIcon style={{ color: voiceReducer?.is_selected_default ? '#888888' : '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                        </span>
                    </CustomTooltip>
                    <CustomTooltip title='Go to voice profiles manager'>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                        // disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={manageProfileHandler} 
                        >
                            <SettingsIcon style={{ color: '#ffffff', fontSize: '21px' }} />
                        </IconButton>
                    </CustomTooltip> */}
                 
                        {/* </> : <>
                        <CustomTooltip title='Show voice profile management options'>
                        <IconButton
                            style={{ marginLeft: 3, marginRight: 3, padding: 0 }}
                        // disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample} 
                            onClick={()=> setShowToolbar(true)} 
                        >
                            <ChevronRightIcon style={{ color: '#ffffff', fontSize: '28px' }} />
                        </IconButton>
                    </CustomTooltip>
                        </>
                    } 
                </Box>*/}
                {
                    isProfileDeleted && <Typography sx={{ color: '#ffffff'}}>* The voice profile associated with this audio clip is either deleted or not available. You may explore other saved voices </Typography>
                }
            </Box>
            <Box className='flex' sx={{flexDirection:{xs: 'column', lg: 'row'}, justifyContent: {xs:'center', lg: 'flex-end'}, alignItems: 'center', gap: 2, mr: 3 }} >
            {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
            {/* <CustomTooltip title={voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'} >
                <Box className = 'flex row j-center a-center' sx={{ gap: 0.5}} >
                    <Typography 
                        sx={{
                            color: '#FFFFFF', 
                            textDecoration: 'underline', 
                            cursor: 'pointer',
                            fontWeight: 700
                            }} 
                        disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                        onClick={playSampleHandler}
                    >
                        {voiceReducer?.is_sample_playing ? 'Pause sample' : 'Play sample'}
                    </Typography>
                    <IconButton
                          sx={{padding: 0}}
                          disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                          onClick={playSampleHandler}
                    >
                    {voiceReducer?.is_sample_playing ? <PauseCircleOutlineRoundedIcon style={{ color: '#00ceff', fontSize: '20px'}}/> 
                                                        : <PlayCircleOutlineRoundedIcon style={{ color: '#ffffff', fontSize: '20px'}}/>
                    }
                    </IconButton>
                    
                </Box>     
             </CustomTooltip> */}
          
                                        {/* <audio ref={audioRef} aria-disabled={!voiceReducer?.audio_url} style={{ height: '32px' }} controls></audio> */}
                                        <CustomButton
                                             type='button'
                                            btnText = {voiceReducer?.is_sample_playing ? 'Pause Sample' : 'Play Sample'}
                                            style={{
                                                borderRadius: '5px',
                                               // border: "none",
                                               color: '#000000',
                                               border: '1px solid #00CEFF',
                                                textTransform: 'none',
                                                backgroundColor: '#00CEFF', 
                                            
                                            }}
                                            sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
               
                                            disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || voiceReducer?.is_generating_sample}
                                            // color="secondary"
                                            onClick={playSampleHandler}
                                           // endIcon={<VolumeUpIcon style={{ fontSize: 18 }} />}
                                            endIcon={pendingCallReducer?.voice_sample ? <CircularProgress thickness={4} size={18} /> : voiceReducer?.is_sample_playing ? <PauseIcon style={{ fontSize: 16 }} /> : <PlayArrowIcon style={{ fontSize: 24 }} />}
                                        />
                                    
                                    <audio ref={sampleRef} aria-disabled={!!voiceReducer?.sample_url}></audio>            
            
             {/* <Typography sx={{color: '#FFFFFF'}} >Or</Typography> */}
            <CustomButton
                btnText={voiceReducer?.is_generating_speech ? 'Please wait...' : 'Create Voice Clip'}
                toolTipTitle = 'Generate voice clip using selected voice profile and script settings.'
                // disabled = {isDisabled || (userReducer.user_name != userReducer.event_details?.owner_name)}
                onClick={generateSpeechClickHandler}
                disabled={voiceReducer?.is_speech_playing || voiceReducer?.is_generating_speech || !voiceReducer?.selected_voice || contentReducer?.editor_text == ""}
                type='button'
                style={{
                    borderRadius: '5px',
                    border: "none",
                    textTransform: 'none',
                    backgroundColor: '#08231B', 
                    color: '#00D744',
                }}
                sx={{ minWidth: '142px', fontSize: { xs: '8px', sm: '9px', md: '10px', lg: '11px', xl: '16px' }, fontWeight: 600, height: { xs: '18px', sm: '20px', md: '22px', lg: '24px', xl: "32px" }, fontFamily: 'Rubik', width: { xs: '80px', md: '100px', lg: '130px', xl: '160px' } }}
                />
                
        </Box>
        </Box>
       
        {/*contentReducer.open_delete_popup && <DeleteConfirmationPopup
            open={contentReducer.open_delete_popup}
            closeHandler={() => dispatch(updateContentState({ open_delete_popup: false }))}
            deleteConfirmHandler={deleteProfileHandler}
            message='You are about to delete a voice profile. You will lose this settings for all the audio clips where it was used. Please type "delete" below to confirm.'
            />*/}
    </Box>
}

export default VoiceProfileView;